import { Directive, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from './login.service';

@Directive({
  selector: '[quillLogInClick]',
})
export class LogInClickDirective {
  constructor(private loginService: LoginService, private router: Router) {}

  @HostListener('click', ['$event']) onClick(event: Event) {
    event.preventDefault();
    this.loginService.login(this.router.url);
  }
}
