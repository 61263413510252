import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthUser, AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'quill-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public footerDate: number;
  user$: Observable<AuthUser | null>;
  constructor(private auth: AuthService) {
    this.user$ = this.auth.user$;
  }

  ngOnInit(): void {
    const now = new Date();
    this.footerDate = now.getFullYear();
  }
}
