import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginFormComponent } from './login-form/login-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { LogoutComponent } from './logout/logout.component';
import { UnauthorisedComponent } from './unauthorised/unauthorised.component';
import { LogInOutModule } from '../log-in-out/log-in-out.module';

@NgModule({
  declarations: [LoginFormComponent, LogoutComponent, UnauthorisedComponent],
  imports: [
    CommonModule,
    LoginRoutingModule,
    CoreModule,
    ReactiveFormsModule,
    LogInOutModule,
  ],
})
export class LoginModule {}
