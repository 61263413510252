import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((x) => x.DashboardModule),
  },
  {
    path: 'books',
    loadChildren: () =>
      import('./books/books.module').then((x) => x.BooksModule),
  },
  {
    path: 'chapters',
    loadChildren: () =>
      import('./chapters/chapters.module').then((x) => x.ChaptersModule),
  },
  {
    path: 'topics',
    loadChildren: () =>
      import('./topics/topics.module').then((x) => x.TopicsModule),
  },
  {
    path: 'frontmatter',
    loadChildren: () =>
    import('./front-matter/front-matter.module').then((x) => x.FrontMatterModule),
  },
  {
    path: 'notifications',
    loadChildren: () =>
      import('./messaging/messaging.module').then((x) => x.MessagingModule),
  },
  {
    path: 'alerts',
    loadChildren: () =>
    import('./alerts/alert.module').then((x) => x.AlertModule),
  },
  {
    path: 'topic-search',
    loadChildren: () =>
      import('./topic-search/topic-search.module').then(
        (x) => x.TopicSearchModule
      ),
  },
  {
    path: 'user-guide',
    loadChildren: () =>
      import('./user-guide/user-guide.module').then((x) => x.UserGuideModule),
    data: { state: 'user-guide' },
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((x) => x.UsersModule),
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import('./schedule/schedule.module').then((x) => x.ScheduleModule),
  },
  {
    path: 'release-notes',
    loadChildren: () =>
      import('./release-notes/release-notes.module').then(
        (x) => x.ReleaseNotesModule
      ),
  },
  {
    path: 'preview',
    loadChildren: () =>
      import('./preview/preview.module').then((x) => x.PreviewModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth-ui/auth-ui.module').then((x) => x.AuthUiModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./login/login.module').then((x) => x.LoginModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
