import { Component, OnInit } from '@angular/core';
import { Placeholders } from 'src/app/shared/enums/placeholders';

@Component({
  selector: 'quill-unauthorised',
  templateUrl: './unauthorised.component.html',
  styleUrls: ['./unauthorised.component.scss'],
})
export class UnauthorisedComponent implements OnInit {
  message: string = Placeholders.Unauthorised;

  constructor() {}

  ngOnInit(): void {}
}
