import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';

import { ModalModule } from '../modal/modal.module';
import { PrintModalComponent } from './print-modal.component';

@NgModule({
  declarations: [PrintModalComponent],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
  ],
  exports: [PrintModalComponent],
})
export class PrintModalModule {}
