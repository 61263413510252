import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateToString',
})
export class DateToStringPipe implements PipeTransform {
  transform(value?: Date, format?: string): string {
    if (!value) return 'No Date';

    if (!format) return value.toDateString();

    const year = value.getFullYear();
    const month = value.getMonth() + 1;
    const day = value.getDate();
    const hours = value.getHours();
    const minutes = value.getMinutes();
    const seconds = value.getSeconds();

    return format
      .replace('yyyy', year.toString())
      .replace('yy', year.toString().slice(-2))
      .replace('MM', month.toString().padStart(2, '0'))
      .replace('dd', day.toString().padStart(2, '0'))
      .replace('HH', hours.toString().padStart(2, '0'))
      .replace('mm', minutes.toString().padStart(2, '0'))
      .replace('ss', seconds.toString().padStart(2, '0'));
  }
}
