import { NgModule } from '@angular/core';
import { ModalComponent } from './modal.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [ModalComponent],
  imports: [CommonModule, ReactiveFormsModule, FontAwesomeModule, CoreModule],
  exports: [ModalComponent],
})
export class ModalModule {}
