<div class="container mb-4">
  <div class="row">
    <div class="col-12 text-center" [routerLink]="'/dashboard'">
      <fa-icon
        [icon]="['fas', 'feather-alt']"
        size="2x"
        title="Quill by NB Medical"
      ></fa-icon>
      <h1 class="pseudoLink">Quill</h1>
      <quill-image-render
        imgSrc="/assets/images/underline.png"
        imgSrcFallback="/assets/images/underline.png"
      ></quill-image-render>
    </div>
  </div>
</div>
