import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { AuthService, AuthUser } from 'src/app/auth/auth.service';
import { ToastService } from 'src/app/services/toasts/services/toast.service';

@Component({
  selector: 'quill-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  loginForm = this.formBuilder.group({
    username: [null, Validators.required],
    password: [null, Validators.required],
  });
  vm$: Observable<{
    user: AuthUser | null;
    hasValidAccessToken: boolean;
  }>;
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private toastService: ToastService
  ) {
    this.vm$ = combineLatest([
      this.auth.user$,
      this.auth.hasValidAccessToken$,
    ]).pipe(
      map(([user, hasValidAccessToken]) => ({
        user,
        hasValidAccessToken,
      })),
      tap(({ user, hasValidAccessToken }) => {
        if (user && hasValidAccessToken) {
          this.toastService.showSuccessMessage('Successfully logged in');
          this.router.navigate(['/dashboard']);
        }
      })
    );
  }
}
