import { Component } from '@angular/core';

import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'quill-toasts',
  template: ` <div
    class="ngb-toasts fixed-top top-0 end-0 p-3 d-flex flex-column align-items-end"
  >
    <nbj-toast-display
      *ngFor="let toast of toastService.toasts"
      [toast]="toast"
    >
    </nbj-toast-display>
  </div>`,
})
export class ToastContainerComponent {
  constructor(public toastService: ToastService) {}
}
