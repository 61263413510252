export enum Placeholders {
  Unavailable = '---',
  Unauthorised = 'You must be logged in to continue',
  NoPermission = 'You do not have permission to peform this action',
  mandatory = 'Error on form. Mandatory field is empty.',
  NBUsername = 'Enter NB Username',
  Loading = 'Loading...',
  CompleteForm = 'Please complete all form fields',
  PagesToPrintRange = 'e.g. 1-3, 5, 7-9',
  PagesToSkipNumberingRange = 'e.g. 1-3, 5, 7-9',
}
