import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MakeHtmlComponent } from './make-html/make-html.component';
import { MakePdfComponent } from './make-pdf/make-pdf.component';

@NgModule({
  declarations: [MakePdfComponent, MakeHtmlComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [MakePdfComponent, MakeHtmlComponent],
})
export class RenderOutputModule {}
