import { Component, HostBinding, Input, TemplateRef } from '@angular/core';

import { ToastInfo } from '../../objects/toast-info';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'nbj-toast-display',
  templateUrl: './toast-display.component.html',
  styleUrls: ['./toast-display.component.scss'],
})
export class ToastDisplayComponent {
  private _preventAutohide: boolean = false;

  constructor(public toastService: ToastService) {}
  @HostBinding('class.ngb-toasts') toasts = true;

  @Input()
  toast!: ToastInfo;

  get autoHide() {
    return this._preventAutohide ? false : this.toast.options.autoHide ?? false;
  }

  preventAutoHide() {
    this._preventAutohide = true;
  }
  allowAutoHide() {
    this._preventAutohide = false;
  }

  isTemplate(toast: string | TemplateRef<any>) {
    return toast instanceof TemplateRef;
  }
  onClick() {
    if (!this.isTemplate(this.toast.textOrTpl)) {
      this.toast.options.autoHide = true;
      this.toastService.remove(this.toast);
    }
  }
}
