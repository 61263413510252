<div class="row mt-5 text-center justify-content-center">
  <div class="col-12 text-center mt-5 pt-5" *ngIf="user$ | async as user">
    <fa-icon
      *ngIf="user.hasAdminAccess"
      [icon]="['fas', 'user']"
      size="2x"
      class="ms-3 me-2 pseudoLink"
      [routerLink]="'/users'"
      title="Users"
    ></fa-icon>
    <fa-icon
      *ngIf="user.hasAdminAccess"
      [icon]="['fas', 'bell']"
      size="2x"
      class="ms-3 me-2 pseudoLink"
      [routerLink]="'/alerts'"
      title="Alerts"
    ></fa-icon>
    <fa-icon
      [icon]="['fas', 'question-circle']"
      size="2x"
      class="ms-3 me-2 pseudoLink"
      [routerLink]="'/user-guide'"
      title="Help"
    ></fa-icon>
    <fa-icon
      [icon]="['fas', 'envelope']"
      size="2x"
      class="ms-3 me-2 pseudoLink"
      [routerLink]="'/notifications'"
      title="Notifications"></fa-icon>
    <fa-icon
      [icon]="['fas', 'calendar-alt']"
      size="2x"
      class="ms-3 me-2 pseudoLink"
      [routerLink]="'/schedule'"
      title="Timetable"
    ></fa-icon>
   <!-- <fa-icon
      *ngIf="user.hasAdminAccess"
      [icon]="['fas', 'file-circle-exclamation']"
      size="2x"
      class="ms-3 me-2 pseudoLink"
      [routerLink]="'/release-notes'"
      title="Release Notes"
    ></fa-icon>-->
    <fa-icon
      [icon]="['fas', 'sign-out-alt']"
      class="ms-3 me-2 pseudoLink"
      size="2x"
      title="Logout"
      quillLogOutClick
    ></fa-icon>
  </div>
  <div class="col-10 col-lg-3">
    <p class="subtitle mt-4 mb-0">
      &copy; {{ footerDate }} P&amp;S Medical Education Limited
    </p>
    <p class="mt-0">
      Registered in England and Wales. Reg No. 8892304<br /><br />
    </p>
  </div>
</div>
