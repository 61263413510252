import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

import { AuthService } from '../auth/auth.service';
import { StorageService } from '../auth/storage-service';
import { UsersService } from '../users/users.service';

const redirectKey = 'login.redirectKey';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private oauthService: OAuthService,
    private authService: AuthService,
    private router: Router,
    private userService: UsersService
  ) {}

  async login(redirectUrl?: string) {
    LoginService.setRedirectUrl(redirectUrl);
    return await this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  private static setRedirectUrl(redirectUrl?: string) {
    if (StorageService.hasStorage) {
      StorageService.setItem(redirectKey, redirectUrl);
    }
  }
  private static getRedirectUrl() {
    if (StorageService.hasStorage) {
      const url = StorageService.getItem(redirectKey);
      StorageService.removeItem(redirectKey);
      return url;
    }
    return null;
  }

  async completeAuthentication(): Promise<boolean> {
    try {
      const url = LoginService.getRedirectUrl();
      if (url) {
        this.router.navigateByUrl(url);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async logout() {
    await this.oauthService
      .loadDiscoveryDocument()
      .then((_) => this.oauthService.revokeTokenAndLogout());
  }

  async softLogout() {
    await this.oauthService.loadDiscoveryDocument().then((_) => {
      this.oauthService.logOut();
      this.authService.reloadUser();
    });
  }

  getChangePasswordUrl(redirectUrl?: string) {
    return `${environment.clientSettings.authority}/account/myaccount?ReturnUrl=${redirectUrl}`;
  }
}
