import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { DataInterceptor } from './interceptors/data-interceptor';
import { AngularDateHttpInterceptor } from './interceptors/date-interceptor';
import { LogInOutModule } from './log-in-out/log-in-out.module';
import { LoginModule } from './login/login.module';
import { ToastsModule } from './services/toasts/toasts.module';
import { CopyModalComponent } from './shared/copy-modal/copy-modal.component';
import { ModalModule } from './shared/modal/modal.module';
import { DateToStringPipe } from './shared/pipes/date-to-string.pipe';
import { PipesModule } from './shared/pipes/pipes.module';
import { PrintModalModule } from './shared/print-modal/print-modal.module';
import { Select2Module } from 'ng-select2-component';

@NgModule({
  declarations: [AppComponent, CopyModalComponent ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    ReactiveFormsModule,
    AuthModule,
    FontAwesomeModule,
    LoginModule,
    ToastsModule,
    LogInOutModule,
    ModalModule,
    PrintModalModule,
    PipesModule,
    Select2Module,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DataInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AngularDateHttpInterceptor,
      multi: true,
    },
    DateToStringPipe,
  ],
  bootstrap: [AppComponent],
  exports: [FontAwesomeModule, Select2Module],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
