<ngb-toast
  [class]="toast.options.className"
  [autohide]="autoHide"
  (mouseenter)="preventAutoHide()"
  (mouseleave)="allowAutoHide()"
  (hidden)="toastService.remove(toast)"
  [delay]="toast.options.delay ?? 0"
  (click)="onClick()"
>
  <ng-template [ngIf]="isTemplate(toast.textOrTpl)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="$any(toast.textOrTpl)"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
