import { Directive, HostListener } from '@angular/core';

import { LoginService } from './login.service';

@Directive({
  selector: '[quillLogOutClick]',
})
export class LogOutClickDirective {
  constructor(private loginService: LoginService) {}

  @HostListener('click', ['$event']) onClick(event: Event) {
    event.preventDefault();
    if (confirm('Are you sure you want to log out?')) {
      this.loginService.logout();
    }
  }
}
