import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from '../log-in-out/login.service';

@Injectable()
export class DataInterceptor implements HttpInterceptor {
  constructor(private login: LoginService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // if not posting to a full url, we'll set the domain from environment
    if (!request.url.startsWith('http')) {
      const url = environment.apiUrl;
      request = request.clone({
        url: url + request.url,
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.login.login();
        }

        let message = 'An error occurred';
        if (error && error.error) {
          if (error.error.reason) {
            message = error.error.reason;
          } else if (typeof error.error === 'object') {
            const parts: any[] = [];
            Object.keys(error.error).forEach((key) => {
              const value = error.error[key];
              if (Array.isArray(value)) {
                parts.push(...value);
              } else {
                parts.push(value);
              }
            });
            message = parts.join(', ');
          }
        }
        // this.errorDialogService.showError(message);
        console.error({ message });
        return throwError(error);
      })
    );
  }
}
