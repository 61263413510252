import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChangePasswordClickDirective } from './change-password-click.directive';
import { LogInClickDirective } from './log-in-click.directive';
import { LogOutClickDirective } from './log-out-click.directive';

@NgModule({
  declarations: [
    LogInClickDirective,
    LogOutClickDirective,
    ChangePasswordClickDirective,
  ],
  imports: [CommonModule],
  exports: [
    LogInClickDirective,
    LogOutClickDirective,
    ChangePasswordClickDirective,
  ],
})
export class LogInOutModule {}
