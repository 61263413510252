import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { UserDetails } from '../core/dtos/user-details';
import { UserSummary } from '../core/dtos/user-summary';
import { RequestService } from '../services/request.service';
import { Modes } from '../shared/enums/modes';
import { Permissions } from '../shared/enums/permissions';
import { Roles } from '../shared/enums/roles';
import { AddUser } from './commands/add-user';
import { DeleteUser } from './commands/delete-user';
import { UpdateUser } from './commands/update-user';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private _mode: any = Modes.readonly;

  private _modeChanged: (mode: any) => void;

  modeChanged(callback: (mode: any) => void) {
    this._modeChanged = callback;
  }

  get mode() {
    return this._mode;
  }
  set mode(mode: any) {
    this._mode = mode;
    this._modeChanged(mode);
  }

  roles = Roles;
  permissions = Permissions;
  role: string = Roles.reviewer;

  loggedIn: boolean = false;
  private thisUser: any;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private requests: RequestService, private router: Router) {}

  get userRole() {
    return this.thisUser?.role;
  }

  get isAdmin() {
    return this.userRole === 'admin';
  }

  set currentMode(mode: any) {
    this.mode = mode;
  }

  get currentMode() {
    return this.mode;
  }

  getUser() {
    return this.thisUser;
  }

  setUser(user: any) {
    this.thisUser = user;
  }

  checkLogin(navigateTo: String[]): void {
    if (this.loggedIn) {
      this.router.navigate(navigateTo);
    } else {
      this.router.navigate(['/login/unauthorised']);
    }
  }

  addUser(newUser: AddUser) {
    return this.requests.adminCommand('AddUser', newUser);
  }

  assignCompanyRoles(updatedUser: any) {
    return this.requests.adminCommand('AssignCompanyRoles', updatedUser);
  }

  deleteUser(user: DeleteUser) {
    return this.requests.adminCommand<UserSummary>('DeleteUser', user);
  }

  updateUser(user: UpdateUser) {
    return this.requests.adminCommand<UserSummary>('UpdateUser', user);
  }

  getAllUsers() {
    return this.requests.adminQuery<UserSummary[]>('GetAllUsers', {});
  }

  getUserDetails(id: string) {
    return this.requests.adminQuery<UserDetails>('GetUserDetails', {
      id,
    });
  }
}
