import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  APP_INITIALIZER,
  NgModule,
  PLATFORM_ID,
  Provider,
} from '@angular/core';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

import { ConfigLoaderService } from './config-loader.service';
import { StorageService } from './storage-service';

export function initializeApp(
  configLoaderService: ConfigLoaderService,

  platform: object
) {
  return async (): Promise<void> => {
    if (isPlatformBrowser(platform)) {
      return await configLoaderService.load();
    }
    return await Promise.resolve();
  };
}

const providers: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    multi: true,
    deps: [ConfigLoaderService, PLATFORM_ID],
  },
];
if (StorageService.hasStorage) {
  providers.push({ provide: OAuthStorage, useFactory: storageFactory });
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true,
      },
    }),
  ],
  exports: [],
  // This is initialised here so that it's guaranteed to happen on app init
  providers: providers,
})
export class AuthModule {}

// We need a factory, since localStorage is not available during AOT build time.
function storageFactory(): OAuthStorage {
  return StorageService.oauthStorage;
}
