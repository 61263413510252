<div class="row justify-content-center">
  <div class="col-10 col-lg-3 text-center">
    <ng-container *ngIf="vm$ | async as vm">
      <a *ngIf="!vm.user" href="#" quillLogInClick
        ><i class="fa-solid fa-user me-2"></i>Login</a
      >
      <ng-container *ngIf="vm.user">
        <div class="alert alert-warning" *ngIf="!vm.user.hasAccess">
          You do not have access to this application.
        </div>

        <h3 *ngIf="vm.user.hasAccess">Welcome, {{ vm.user.displayName }}</h3>
        <div class="alert alert-success my-5" *ngIf="vm.user.hasAdminAccess">
          <fa-icon
            [icon]="['fas', 'check-circle']"
            size="2x"
            class="me-3"
            title="Access Granted"
          ></fa-icon>
          <br />
          You have admin access to this application.
        </div>
        <a href="#" quillLogOutClick
          ><i class="fa-solid fa-user me-2"></i>Logout</a
        >
      </ng-container>
    </ng-container>
    <ng-template #popContent
      >Contact the NB Medical Development team for help</ng-template
    >
    <ng-template #popTitle>Forgotten Password?</ng-template>
    <p
      class="mt-3"
      [ngbPopover]="popContent"
      [popoverTitle]="popTitle"
      placement="top"
    >
      <small>Forgotten password?</small>
    </p>
  </div>
</div>
