import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { map, Observable, of, switchMap, take } from 'rxjs';

import { ValidatedResponse } from './dtos/validated-response';
import { ToastService } from './toasts/services/toast.service';
import { StorageService } from '../auth/storage-service';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    private http: HttpClient,
    private oauthService: OAuthService,
    private toastService: ToastService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  imageGet<TResponse>(imageId: string) {
    return this.http.get<TResponse>('/images/' + imageId, {
      headers: this.getUserHeaders(),
    });
  }

  publicQuery<TResponse>(type: string, data: any) {
    return this.query<TResponse>('/public/query', type, data);
  }

  userQuery<TResponse>(type: string, data: any) {
    return this.query<TResponse>('/user/query', type, data);
  }

  userCommand<TResponse>(type: string, data: any): Observable<TResponse> {
    return this.command<TResponse>('/user/command', type, data);
  }

  adminQuery<TResponse>(type: string, data: any) {
    return this.query<TResponse>('/admin/query', type, data);
  }

  adminCommand<TResponse>(type: string, data: any): Observable<TResponse> {
    return this.command<TResponse>('/admin/command', type, data);
  }

  userUpload<TResponse>(
    type: string,
    data: any,
    file: File
  ): Observable<TResponse | undefined> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', type);
    formData.append('data', JSON.stringify(data));
    return this.http
      .post<ValidatedResponse<TResponse>>('/user/upload', formData, {
        responseType: 'json',
        headers: this.getUserHeaders(),
      })
      .pipe(
        map((response: any) => {
          if (response.isValid) {
            return response.response;
          } else {
            if (response.errors.length) {
              for (const error of response.errors) {
                this.toastService.showErrorMessage(error);
              }
            } else {
              this.toastService.showErrorMessage('An error occurred');
            }
            return response.response ?? undefined;
          }
        })
      );
  }

  userDownload(type: string, data: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return of();
    }
    const formData = new FormData();
    formData.append('type', type);
    formData.append('data', JSON.stringify(data));
    if (StorageService.isSSR) {
      return of(null);
    }
    return this.http.post('/user/download', formData, {
      responseType: 'blob',
      headers: this.getUserHeaders(),
    });
  }

  private query<TResponse>(endpoint: string, type: string, data: any) {
    const params: { [key: string]: string } = {};
    params['type'] = type;
    if (data) {
      params['data'] = JSON.stringify(data);
    }
    return this.http.get<TResponse>(endpoint, {
      responseType: 'json',
      params,
      headers: this.getUserHeaders(),
    });
  }

  private command<TResponse>(
    endpoint: string,
    type: string,
    data: any
  ): Observable<TResponse> {
    return this.http
      .post<ValidatedResponse<TResponse>>(
        endpoint,
        { type, data },
        { responseType: 'json', headers: this.getUserHeaders() }
      )
      .pipe(
        map((response: any) => {
          if (response.isValid) {
            return response.response;
          } else {
            if (response.errors.length) {
              for (const error of response.errors) {
                this.toastService.showErrorMessage(error);
              }
            } else {
              console.log('command error');
              this.toastService.showErrorMessage('An error occurred');
            }
            return response.response ?? undefined;
          }
        })
      );
  }

  private getUserHeaders() {
    const data = {
      Authorization: this.oauthService.authorizationHeader(),
    };
    return data;
  }
}
