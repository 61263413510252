import { Injectable } from '@angular/core';

import { authConfig } from './auth-config';
import { AuthService } from './auth.service';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { RequestService } from '../services/request.service';
import { firstValueFrom } from 'rxjs';
import { ToastService } from '../services/toasts/services/toast.service';
import { SimpleInfo } from '../core/dtos/simple-info';

@Injectable({
  providedIn: 'root',
})
export class ConfigLoaderService {
  constructor(
    private oauthService: OAuthService,
    private authService: AuthService,
    private requestService: RequestService,
    private toast: ToastService
  ) {}

  public async load(): Promise<void> {
    await this.loadConfig(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private async loadConfig(authConfig: AuthConfig): Promise<void> {
    this.oauthService.configure(authConfig);
    this.oauthService.events.subscribe((event) => {
      if (event.type === 'token_received') {
        this.updateUserFromToken();
      }
      if (event.type === 'logout') {
        this.updateUserFromToken();
      }
    });
    await this.tryUpdateUser();
  }

  private async tryUpdateUser() {
    try {
      const loginSuccess =
        await this.oauthService.loadDiscoveryDocumentAndTryLogin();
      if (loginSuccess) {
        await this.updateUserFromToken();
      }
    } catch (error) {
      this.authService.setUser({
        accessToken: null,
        hasAccess: false,
        role: null,
      });
      console.error({ error });
    }
  }

  private async updateUserFromToken() {
    const hasValidAccessToken = this.oauthService.hasValidAccessToken();
    this.authService.setHasAccessToken(hasValidAccessToken);
    if (hasValidAccessToken) {
      const hasAccess = await firstValueFrom(
        this.requestService.publicQuery<{
          user: boolean;
          role: SimpleInfo | null;
        }>('IsValidUser', {})
      );

      this.authService.setUser({
        accessToken: this.oauthService.getAccessToken(),
        hasAccess: hasAccess.user,
        role: hasAccess.role,
      });
      if (!hasAccess) {
        this.toast.showErrorMessage(
          'You are not authorized to access this page'
        );
      }
    } else {
      this.authService.setUser({
        accessToken: null,
        hasAccess: false,
        role: null,
      });
    }
  }
}
