import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule, NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { LogInOutModule } from '../log-in-out/log-in-out.module';
import { RemoteContentModule } from '../remote-content/remote-content.module';
import { BookLockedMessageComponent } from './book-locked-message/book-locked-message.component';
import { FooterComponent } from './footer/footer.component';
import { ReactiveCheckboxComponent } from './form-controls/reactive-checkbox/reactive-checkbox.component';
import { ReactiveRadioButtonComponent } from './form-controls/reactive-radio-button/reactive-radio-button.component';
import { ReactiveSelectComponent } from './form-controls/reactive-select/reactive-select.component';
import { HeaderComponent } from './header/header.component';
import { ImageRenderComponent } from './image-render/image-render.component';
import { MakeHtmlComponent } from './render-output/make-html/make-html.component';
import { MakePdfComponent } from './render-output/make-pdf/make-pdf.component';
import { RenderOutputModule } from './render-output/render-output.module';
import { TagTopicComponent } from '../topics/tag-topic/tag-topic.component';
import { TagBadgeComponent } from './tags/tag-badge/tag-badge.component';

@NgModule({
  declarations: [
    ImageRenderComponent,
    HeaderComponent,
    FooterComponent,
    ReactiveRadioButtonComponent,
    ReactiveCheckboxComponent,
    ReactiveSelectComponent,
    BookLockedMessageComponent,
    TagBadgeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RemoteContentModule,
    NgbDropdownModule,
    RouterModule,
    NgbToastModule,
    ReactiveFormsModule,
    RenderOutputModule,
    LogInOutModule,
  ],

  exports: [
    ImageRenderComponent,
    FontAwesomeModule,
    HeaderComponent,
    FooterComponent,
    NgbModule,
    ReactiveRadioButtonComponent,
    ReactiveCheckboxComponent,
    ReactiveSelectComponent,
    MakePdfComponent,
    MakeHtmlComponent,
    BookLockedMessageComponent,
    TagBadgeComponent
  ],
})
export class CoreModule {}
